<template>
  <div class="container">
    <page-header
      :title="`문의할 내용을 알려주세요.`"
      titleSize="3.2rem"
      :subtitle="'전문 상담사가 확인 후 최대한 빨리 연락드립니다.'"
    />
    <!-- v1.0.0 내가 선택한 상품 추가 -->
    <div>
      <div class="ui-border-line ui-h-0 ui-mb-1"></div>
      <h4 v-if="artistOption.length || extProd.length || artistData.priceType == 'fixed'">내가 선택한 상품</h4>
      <div class="ui-border-line"></div>
      <ul v-if="artistOption.length" class="reservation-option">
        <li v-for="(option, index) in artistOption" :key="index">
          <span>옵션 명 : {{ option.label }}</span>
          <span>수량 : {{ option.count }}개</span>
          <span>옵션 추가 금액 : {{ $lib.addComma(option.price * option.count) }}원</span>
        </li>
      </ul>
      <ul v-if="extProd.length" class="reservation-option">
        <li v-for="(prod, index) in extProd" :key="index">
          <span>추가 상품명 : {{ prod.label }}</span>
          <span>수량 : {{ prod.count }}개</span>
          <span>추가 금액 : {{ $lib.addComma(prod.price * prod.count) }}원</span>
        </li>
      </ul>
      <div v-if="artistData.priceType == 'fixed'" class="reservation-option-total">
        총 상품 금액 : {{ $lib.addComma(finalPrice) }}원
      </div>
      <div class="ui-border-line" style="height: 10px"></div>
    </div>
    <!-- // v1.0.0 내가 선택한 상품 추가 -->

    <div v-if="[1].indexOf(cusFlag) === 0">
      <div class="ui-border-line ui-h-0 ui-mb-1"></div>
      <h4>필요 내용</h4>
      <div class="ui-border-line"></div>
      <input
        type="text"
        v-model="usage"
        required
      />
      <div class="ui-border-line" style="height: 10px"></div>
    </div>

    <div>
      <div class="ui-border-line ui-h-0 ui-mb-1"></div>
      <h4>필요한 날짜 / 시간</h4>
      <div class="ui-border-line"></div>
      <input
        type="text"
        v-model="runday"
        v-mask="'####.##.## / ##시 ##분'"
        required
      />
      <div class="ui-border-line" style="height: 10px"></div>
    </div>

    <div>
      <div class="ui-border-line ui-h-0 ui-mb-1"></div>
      <h4>필요한 지역 (시/구/동)</h4>
      <div class="ui-border-line"></div>
      <input
        type="text"
        v-model="location"
        required
      />
      <div class="ui-border-line" style="height: 10px"></div>
    </div>

    <div>
      <div class="ui-border-line ui-h-0 ui-mb-1"></div>
      <h4>문의, 요청사항</h4>
      <div class="ui-border-line"></div>
      <input
        type="text"
        v-model="contents"
        required
      />
      <div class="ui-border-line" style="height: 10px"></div>
    </div>

    <footer-box
      submitText="예약 문의하기"
      :submitCb="() => postOffer()"
      :submitDisabled="!validate"
    >
    </footer-box>
  </div>
</template>

<script>
import PageHeader from "@/components/common/PageHeader";
import FooterBox from "@/components/common/FooterBox";
import { isEarlybirdEventArtist, isDiscountEventCustomArtist } from "@/lib/artist.js";

export default {
  components: {
    PageHeader,
    FooterBox,
  },
  data() {
    return {
      formData: {
        artistOption: [],
        extProd: [],
        genre: '',
        usage: null,
        location: null,
        runday: null,
        contents: null,
        itemType: '',
        categoryName: '',
      },
      artistData: {},
      artistOption: [],
      extProd: [],
    };
  },
  computed: {
    cusFlag() {
      return this.$config.constant.cusFlag.indexOf(this.artistData.cusFlag);
    },
    usage: {
      get(){
        return this.formData.usage;
      },
      set(value) {
        this.$set(this.formData, 'usage', value);
      },
    },
    location: {
      get(){
        return this.formData.location;
      },
      set(value) {
        this.$set(this.formData, 'location', value);
      },
    },
    runday: {
      get(){
        return this.formData.runday;
      },
      set(value) {
        this.$set(this.formData, 'runday', value);
      },
    },
    contents: {
      get(){
        return this.formData.contents;
      },
      set(value) {
        this.$set(this.formData, 'contents', value);
      },
    },
    validate() {
      let validateData = {
        genre: false,
        usage: true,
        location: true,
        runday: true,
        contents: true,
      };
      if (this.cusFlag === 2) {
        validateData.usage = false;
      }

      let validate = true;
      for (let key in this.formData) {
        if (validateData[key] && !this.formData[key]) {
          validate = false;
          break;
        }
      }
      return validate;
    },
    isCustom() {
      if (this.cusFlag === 5) {
        return false
      }
      return this.cusFlag >= 2 || this.cusFlag === 0;
    },
    paymentPrice() {
      return Number(this.artistData.cusPriceStart);
    },
    afterDiscountPaymentPrice() {
      return this.$lib.floor(this.paymentPrice - this.$lib.floor(this.paymentPrice * this.artistSalePer / 100), 2);
    },
    afterFeesPaymentPrice() {
      return this.afterDiscountPaymentPrice;
    },
    finalPrice() {
      let optionProductPrice = (
        this.artistOption.reduce((p, c, i) => p + (this.afterFeesPaymentPrice + c.price) * c.count, 0) +
         Number(this.artistData.addWorkPrice)
      );
      let realOptionPrice = optionProductPrice - Number(this.artistData.addWorkPrice);
      let productPrice = realOptionPrice == 0 ? this.afterFeesPaymentPrice + Number(this.artistData.addWorkPrice) : optionProductPrice;
      let extProdPrice = this.extProd.reduce((p, c, i) => p + (c.price * c.count), 0);

      return productPrice + extProdPrice;
    },
    artistSalePer() {
      const salePer = Number(this.artistData?.salePer) || 0;
      const useSalePer = [
        () => this.isEarlybirdEventArtist,
        () => this.isCustom && this.isDiscountEventCustomArtist,
      ].some((test) => test());

      if (useSalePer) {
        return salePer;
      }

      return 0;
    },
    isEarlybirdEventArtist() {
      return isEarlybirdEventArtist(this.artistData, this.offerData);
    },
    isDiscountEventCustomArtist() {
      return isDiscountEventCustomArtist(this.artistData);
    },
  },
  beforeMount() {
    const { artistidx } = this.$route.params;

    let artistData = sessionStorage.getItem(`artistData:${artistidx}`);

    if (!artistidx || !artistData) {
      this.$router.back();
      return;
    }
    this.artistData = JSON.parse(artistData);
    if (!this.artistData.optionData.length) localStorage.removeItem(`artistOption:${artistidx}`);
    if (!this.artistData.extProdData.length) localStorage.removeItem(`extProd:${artistidx}`);

    let artistOption = localStorage.getItem(`artistOption:${artistidx}`);
    let extProd = localStorage.getItem(`extProd:${artistidx}`);
    
    if (artistOption) {
      this.artistOption = JSON.parse(artistOption);
      this.formData.artistOption = this.artistOption;
      this.$set(this.formData, 'artistOption', this.artistOption);
    }

    if (extProd) {
      this.extProd = JSON.parse(extProd);
      this.formData.extProd = this.extProd;
      this.$set(this.formData, 'extProd', this.extProd);
    }


    this.formData.genre = this.artistData.genre;
    this.formData.itemType = this.artistData.categoryType == 'profile' ? '프로필' : this.artistData.categoryType == 'show' ? '행사패키지' : '행사용품';
    this.formData.categoryName = this.artistData.categoryName;
  },
  methods: {
    postOffer() {
      if (!this.validate) return;

      const req = {
        method: "post",
        url: `/client/${this.$route.params.artistidx}/cusOrder`,
        data: this.formData,
      };

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data);

          const resultData = this.$lib.resultCheck(data.resultData);

          if (resultData.success) {
            this.$toast("문의가 전달되었습니다").push("/home");
          } else {
            this.$alert(resultData);
          }
        })
        .catch(log.error);
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  margin: 0 !important;
  font-weight: 500 !important;
  font-size: 2.4rem !important;
  line-height: 6.6rem !important;
}

input,
textarea {
  padding: 0;
  width: 100%;
  border: 0;
  font-size: 2rem;
  line-height: 6rem;
}

textarea {
  line-height: 3rem;
  min-height: 14rem;
  padding: 3rem 0;
}

p {
  color: #979797;
  font-size: 2rem;
}
</style>
